<template>
  <v-form ref="form" v-model="isValid">
    <!-- items -->
    <v-fade-transition>
      <div v-if="form.id">
        <div class="text-overline mb-2 d-flex">
          Elemente
        </div>
        <div v-if="form.formSectionItems.length < 1">
          Bisher wurden dem Formular keine Elemente hinzugefügt.
        </div>
        <template v-else>
          <Draggable
            v-model="form.formSectionItems"
            handle=".draggable"
            group="sections"
            @end="updateOrder"
          >
            <template v-for="(formItem, index) in form.formSectionItems">
              <div :key="`${form.id}-item-${index}`" class="mb-3">
                <models-form-item-item :form-item="formItem" />
              </div>
            </template>
          </Draggable>
        </template>
        <div class="mt-2">
          <models-form-item-parent-dialog />
        </div>
      </div>
    </v-fade-transition>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    // functional
    draftLabel: {
      name: "Entwurf",
      description: "Ein Entwurf ist durch Mandanten nicht auswählbar.",
      icon: "mdi-file-outline",
      color: "grey lighten-2"
    },
    noDraftLabel: {
      name: "Veröffentlicht",
      description:
        "Ein veröffentlichtes Formular ist durch Mandanten auswählbar.",
      icon: "mdi-check-circle",
      color: "success"
    },
    isValid: false,
    loading: false
  }),
  computed: {
    form: {
      set(val) {
        this.$store.commit("form/setForm", val);
      },
      get() {
        return this.$store.getters["form/form"];
      }
    },
    rules() {
      return this.$store.getters["application/rules"];
    },
    saving: {
      set(val) {
        this.$store.commit("form/setSaving", val);
      },
      get() {
        return this.$store.getters["form/saving"];
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      updateForm: "form/update",
      updateOrderForm: "form/updateOrder"
    }),
    updateOrder() {
      this.saving = true;
      this.updateOrderForm({
        id: this.form.id,
        payload: { formSectionItems: this.form.formSectionItems }
      }).finally(() => {
        this.saving = false;
      });
    }
  }
};
</script>

<style scoped></style>
