<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card title="Seite löschen">
      <template v-slot:text>
        <p>Möchten Sie die Seite wirklich löschen?</p>
        Wenn Sie die Seite löschen, werden all Ihre Anforderungen mit Ihr
        gelöscht.
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text class="mr-2" @click="close">
          Abbrechen
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="submit">
          Löschen
          <v-icon right dark>
            mdi-delete-outline
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    pageId: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    // functional
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    pages() {
      return this.$store.getters["page/pages"];
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {},
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      destroyPage: "page/destroy",
      indexPages: "page/index",
      updateOrder: "page/updateOrder"
    }),

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function

      this.destroyPage({ id: this.pageId })
        .then(() => {
          this.indexPages().then(r => {
            this.updateOrder({ payload: r.data.pages });
          });
          this.$snotify.success("Die Seite wurde erfolgreich gelöscht.");
          this.close();
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Löschen der Seite ist ein Fehler aufgetreten."
          );
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
