var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{staticClass:"custom-link",attrs:{"to":_vm.pageItem.attachable_type === 'App\\Models\\Document'
        ? {
            name: 'Documents',
            query: { document_id: _vm.pageItem.attachable_id }
          }
        : {
            name: 'FormsRoute',
            params: { id: _vm.pageItem.attachable_id }
          }}},[_c('v-card',{attrs:{"flat":"","color":"grey lighten-4"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-action',[_c('v-avatar',{attrs:{"icon":"","color":"transparent"}},[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v(" "+_vm._s(_vm.pageItem.is_fulfilled ? "mdi-check-circle" : "mdi-checkbox-blank-circle-outline")+" ")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.pageItem.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.pageItem.description)+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }