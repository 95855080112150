import axios from "axios";

export default {
  index(ctx) {
    return new Promise((resolve, reject) => {
      axios
        .get("/pages")
        .then(response => {
          resolve(response);
          ctx.commit("setPages", response.data.pages);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  store(ctx, { payload }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/pages", { page: payload })
        .then(response => {
          resolve(response);
          ctx.commit("setPage", response.data.page);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  show(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/pages/${id}`)
        .then(response => {
          resolve(response);
          ctx.commit("setPage", response.data.page);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  showBySlug(ctx, { slug }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/pages/by-slug/${slug}`)
        .then(response => {
          resolve(response);
          ctx.commit("setPage", response.data.page);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  update(ctx, { id, payload }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/pages/${id}`, { page: payload })
        .then(response => {
          resolve(response);
          ctx.commit("setPage", response.data.page);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateOrder(ctx, { payload }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/pages/update-order`, { pages: payload })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  destroy(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/pages/${id}`)
        .then(response => {
          resolve(response);
          ctx.commit("setPage", {});
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
