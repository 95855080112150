import Vue from "vue";
import Vuex from "vuex";

/**
 * modules
 */
import application from "./application";
import state from "./state";
import user from "./user";

/**
 * model modules
 */
import confidant from "./modules/confidant";
import document from "./modules/document";
import form from "./modules/form";
import formItem from "./modules/formItem";
import page from "./modules/page";
import pageItem from "./modules/pageItem";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    application,
    state,
    user,

    // models
    confidant,
    document,
    form,
    formItem,
    page,
    pageItem
  }
});
