// eslint-disable-next-line no-unused-vars
import Middleware from "@/middleware/index";
// eslint-disable-next-line no-unused-vars
import PassThrough from "@/views/auth/views/PassThrough";
// eslint-disable-next-line no-unused-vars
import PassThroughMain from "@/views/auth/views/PassThroughMain";

export default [
  {
    path: "p/:slug",
    name: "PagesShow",
    components: {
      main: () =>
        import(/* webpackChunkName: "auth" */ "../views/auth/views/Pages/Show")
    },
    meta: {
      barTitle: "Seite",
      middleware: [Middleware.auth]
    }
  }
];
