<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">
        Vertrauensperson hinzufügen
        <v-icon right dark>mdi-plus</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card title="Vertrauensperson hinzufügen">
      <template v-slot:text>
        <v-form v-model="isValid" @submit.prevent="submit">
          <p>
            Vertrauenspersonen können durch Eingabe eines Aktivierungscodes
            Zugriff auf Ihre Unterlagen erhalten.
          </p>
          Durch hinzufügen der Vertrauensperson, bekommt diese eine E-Mail mit
          dem Aktivierungscode zugesendet.
          <div class="mt-4">
            <v-text-field
              v-model="confidant.first_name"
              label="Vorname"
              filled
              :rules="[rules.required]"
            />
            <v-text-field
              v-model="confidant.last_name"
              label="Nachname"
              filled
              :rules="[rules.required]"
            />
            <v-text-field
              v-model="confidant.email"
              label="E-Mail-Adresse"
              filled
              :rules="[rules.required, rules.email]"
            />
          </div>
        </v-form>
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text class="mr-2" @click="close">
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!isValid"
          :loading="loading"
          @click="submit"
        >
          Hinzufügen
          <v-icon right dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    itemProp: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    confidant: {},

    // functional
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    itemProp(val) {
      this.section = val;
    }
  },
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      storeConfidant: "confidant/store",
      getState: "state/userState"
    }),

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function
      this.storeConfidant({
        payload: { confidant: this.confidant, email: this.confidant.email }
      })
        .then(() => {
          this.$snotify.success("Erfolgreich hinzugefügt.");
          this.getState();
        })
        .finally(() => {
          this.close();
        });
    }
  }
};
</script>

<style scoped></style>
