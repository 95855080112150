<template>
  <div class="mx-n4">
    <v-row no-gutters>
      <template v-for="(page, index) in pages">
        <v-col :key="`${index}-column`" cols="12" :md="pagesColumn">
          <organisms-pages-timeline-item :page="page" :index="index + 1" />
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({}),
  computed: {
    pages() {
      return this.$store.getters["page/pages"];
    },
    pagesColumn() {
      if (this.pages && this.pages.length > 0) {
        return Math.round(12 / this.pages.length);
      }
      return 3;
    }
  },
  watch: {},
  created() {
    this.indexPages();
  },
  methods: {
    ...mapActions({
      indexPages: "page/index"
    })
  }
};
</script>

<style scoped></style>
