import axios from "axios";

export default {
  index(ctx, { onlyNoDraft }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/forms", { params: { onlyNoDraft: onlyNoDraft } })
        .then(response => {
          resolve(response);
          ctx.commit("setForms", response.data.forms);
          if (response.data.formsOwn)
            ctx.commit("setFormsOwn", response.data.formsOwn);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  store(ctx, { payload }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/forms", payload)
        .then(response => {
          resolve(response);
          ctx.commit("setForm", response.data.form);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  storeFromTemplate(ctx, { templateId }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/forms/${templateId}/store-from-template`)
        .then(response => {
          resolve(response);
          ctx.commit("setForm", response.data.form);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  show(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/forms/${id}`)
        .then(response => {
          resolve(response);
          ctx.commit("setForm", response.data.form);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  update(ctx, { id, payload, noCommit }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/forms/${id}`, payload)
        .then(response => {
          resolve(response);
          if (!noCommit) ctx.commit("setForm", response.data.form);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateOrder(ctx, { id, payload }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/forms/${id}/update-order`, payload)
        .then(response => {
          resolve(response);
          ctx.commit("setForm", response.data.form);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  destroy(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/forms/${id}`)
        .then(response => {
          resolve(response);
          ctx.commit("setForm", {});
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
