<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" small v-bind="attrs" v-on="on">
        Hilfe
        <v-icon small right dark>mdi-help-circle</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card title="Hilfe">
      <template v-slot:text>
        <p></p>
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn color="primary" @click="dialog = false">
          Okay
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    itemProp: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    // functional
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {},
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({}),

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
    }
  }
};
</script>

<style scoped></style>
