var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","xl":"11","lg":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-10 py-6",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-h3"},[_vm._v(" Willkommen bei NALA! ")])])],1)],1),_c('v-card',{attrs:{"flat":"","color":"accent"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-10 py-6",attrs:{"cols":"12","md":"12"}},[_c('v-card-title',{staticClass:"text-h4"},[_c('dashboard-greeting')],1),_c('v-card-text',{staticClass:"text-h5"},[_vm._v(" Genießen Sie den smarten Service, den Sie verdient haben. ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-2"},_vm._l((_vm.cards),function(card,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"4"}},[_c('v-hover',{staticClass:"transition-ease-in-out",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex justify-space-between flex-column",attrs:{"elevation":hover ? 8 : 2,"to":card.to,"height":"100%"}},[_c('div',[_c('v-card-title',{staticClass:"align-center"},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-archive-outline")]),_c('div',[_vm._v(_vm._s(card.title))])],1),_c('v-card-text',[_vm._v(_vm._s(card.description))])],1)])]}}],null,true)})],1)}),1),_c('div',{staticClass:"text-overline mt-4"},[_vm._v(" Vorschau ")]),_c('div',{staticClass:"mb-4"},[_c('organisms-pages-timeline')],1),_c('div',{staticClass:"text-overline mt-16"},[_vm._v(" Vorsorgelevel ")]),(_vm.pages && _vm.pages[0])?_c('div',{staticClass:"mb-4"},[_c('v-card',{staticClass:"d-flex flex-nowrap align-center justify-start"},[_c('div',{staticClass:"pa-4"},[_c('v-progress-circular',{attrs:{"rotate":"90","color":"primary","size":"240","width":"20","value":_vm.progressValue}},[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.progressValueText))])])],1),_c('div',{staticClass:"pa-4 flex-grow-1"},[_c('div',{staticClass:"text-h5 mb-4"},[_vm._v(" Empfehlung für die nächsten Schritte ")]),(_vm.pages[0].pageItems)?_c('v-card',{staticClass:"mt-4"},[_vm._l((_vm.pages[0].pageItems),function(pageItem,pageIndex){return [_c('v-list-item',{key:(pageIndex + "-itemx"),attrs:{"link":"","to":pageItem.attachable_type === 'App\\Models\\Document'
                      ? {
                          name: 'Documents',
                          query: { document_id: pageItem.attachable_id }
                        }
                      : {
                          name: 'FormsRoute',
                          params: { id: pageItem.attachable_id }
                        }}},[_c('v-list-item-action',[_c('v-avatar',{attrs:{"icon":"","color":"transparent"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(pageItem.is_fulfilled ? "mdi-check-circle" : "mdi-checkbox-blank-circle-outline")+" ")])],1)],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(pageItem.name)+" ")])],1)]})],2):_vm._e()],1)])],1):_vm._e(),_c('div',{staticClass:" pt-16"},[_c('models-confidant-overview')],1),_c('div',{staticClass:" pt-16"},[_c('models-confidant-overview-of')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }