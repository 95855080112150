<template>
  <div>
    <v-card>
      <v-list-item>
        <v-list-item-action>
          <div class="d-flex justify-center">
            <v-btn icon class="draggable-handle mr-2">
              <v-icon>mdi-drag-horizontal-variant</v-icon>
            </v-btn>

            <v-avatar color="primary" class="white--text" size="40">
              <v-fade-transition>
                <span>{{ index }}</span>
              </v-fade-transition>
            </v-avatar>
          </div>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ page.name }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <div>
            <v-btn
              icon
              :to="{ name: 'SettingsPagesEdit', params: { id: page.id } }"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <organisms-pages-delete-dialog :page-id="page.id" />
          </div>
        </v-list-item-action>
      </v-list-item>
      <ui-secondary-divider />
      <v-subheader>
        Anforderungen {{ `(${page.pageItems.length})` }}
        <v-btn small icon @click="isExpanded = !isExpanded">
          <v-icon small>
            {{ !isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
      </v-subheader>
      <v-expand-transition>
        <div v-if="isExpanded">
          <div v-if="page.pageItems && page.pageItems.length > 0">
            <v-card-text>
              <v-card flat color="grey lighten-3">
                <template v-for="pageItem in page.pageItems">
                  <v-list-item :key="`${pageItem.id}-list-item`">
                    <v-list-item-content>
                      <v-list-item-title>{{ pageItem.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div>
                        <organisms-page-items-delete-dialog
                          :page-item-id="pageItem.id"
                        />
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-card>
            </v-card-text>
          </div>
          <div v-else>
            <v-list-item>
              Noch keine Anforderungen hinzugefügt
            </v-list-item>
          </div>
          <organisms-page-items-dialog :page-id="page.id" />
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    isExpanded: false
  }),
  computed: {},
  watch: {},
  created() {}
};
</script>

<style scoped></style>
