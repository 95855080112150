import axios from "axios";

export default {
  store(ctx, { payload }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/confidants", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  activate(ctx, { payload }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/confidants/activate`, payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  show(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/confidants/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  destroy(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/confidants/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
