<template>
  <div>
    <router-view></router-view>

    <!-- set progressbar -->
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>
<style lang="scss">
.no-active:before {
  opacity: 0 !important;
}
.v-card__title {
  word-break: normal !important;
}
.theme--light.v-icon {
  color: #333333 !important;
}

.custom-link {
  color: inherit !important;
  text-decoration: none;
}

.custom-container {
  height: 100%;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
}

.v-card__text {
  color: #333333 !important;
  font-size: 16px !important;
  line-height: 1.4 !important;
}
</style>
<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish();
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  created() {
    /* let self = this;
    this.axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        self.$snotify.info("ALARM");
        console.log(error);
      }
    ); */

    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      const meta = {
        func: [
          {
            call: "color",
            modifier: "temp",
            argument: this.$vuetify.theme.themes.light.secondary
          }
        ]
      };
      this.$Progress.parseMeta(meta);
      //  start the progress bar
      this.$Progress.start();
      //  continue to next page
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    // eslint-disable-next-line no-unused-vars
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      this.$Progress.finish();
    });

    // mobile drawer
    if (window.innerWidth >= 600) {
      this.$store.commit("state/setIsMobileDrawerOpen", true);
    }
  },
  methods: {
    onResize() {
      this.$store.commit("state/setIsMobile", window.innerWidth < 600);
    }
  }
};
</script>
