<template>
  <div>
    <div v-if="page">
      <div class="mb-2 header">
        <div
          class="progress"
          :class="page.is_fulfilled ? 'primary' : 'grey lighten-2'"
        >
          <div
            class="primary"
            style="height: 100%;"
            :style="`width: ${progress1}%;`"
          ></div>
        </div>
        <div class="badge">
          <router-link
            :to="{ name: 'PagesShow', params: { slug: page.slug } }"
            class="custom-link"
          >
            <v-avatar color="primary" size="50" class="white--text">
              {{ index }}
            </v-avatar>
          </router-link>
        </div>
        <div
          class="progress"
          :class="page.is_fulfilled ? 'primary' : 'grey lighten-2'"
        >
          <div
            class="primary"
            style="height: 100%;"
            :style="`width: ${progress2}%;`"
          ></div>
        </div>
      </div>
      <div class="px-4 mt-4">
        <div class="text-h5 text-center">
          {{ page.name }}
        </div>
        <v-card v-if="page.pageItems" class="mt-4">
          <template v-for="(pageItem, pageIndex) in page.pageItems">
            <v-list-item
              :key="`${pageIndex}-itemx`"
              link
              :to="
                pageItem.attachable_type === 'App\\Models\\Document'
                  ? {
                      name: 'Documents',
                      query: { document_id: pageItem.attachable_id }
                    }
                  : {
                      name: 'FormsRoute',
                      params: { id: pageItem.attachable_id }
                    }
              "
            >
              <v-list-item-action>
                <v-avatar icon color="transparent">
                  <v-icon color="primary">
                    {{
                      pageItem.is_fulfilled
                        ? "mdi-check-circle"
                        : "mdi-checkbox-blank-circle-outline"
                    }}
                  </v-icon>
                </v-avatar>
              </v-list-item-action>

              <v-list-item-content>
                {{ pageItem.name }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data: () => ({}),
  computed: {
    fulfilledPageItems() {
      return this.page.pageItems.filter(item => !!item.is_fulfilled);
    },
    progress1() {
      if (this.page.pageItems.length > 0) {
        let ratio = this.fulfilledPageItems.length / this.page.pageItems.length;
        if (ratio <= 0.5) {
          return ratio * 200;
        }
        return 100;
      }
      return 0;
    },
    progress2() {
      let ratio = this.fulfilledPageItems.length / this.page.pageItems.length;
      ratio = ratio - 0.5;
      if (ratio >= 0.0) {
        return ratio * 200;
      }
      return 0;
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped>
.header {
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.header > .progress {
  flex: 2 1 auto;
  height: 5px;
}
.header > .badge {
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
}
</style>
