<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :loading="loading"
    multi-sort
    :footer-props="{
      itemsPerPageText: 'Einträge pro Seite',
      itemsPerPageOptions: [10, 25, 50, 100, -1],
      itemsPerPageAllText: 'Alle'
    }"
  >
    <template v-slot:item.action="{ item }">
      <v-tooltip left>
        <template v-if="editRoute" v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            small
            icon
            :to="{ name: editRoute, params: { id: item.id } }"
            exact
            v-on="on"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>Ansicht</span>
      </v-tooltip>
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | moment("DD.MM.YYYY") }}
    </template>
    <template v-slot:item.updated_at="{ item }">
      {{ item.updated_at | moment("DD.MM.YYYY") }}
    </template>
    <template v-slot:item.removeConfidant="{ item }">
      <models-confidant-delete-dialog :item="item" />
    </template>
    <template v-slot:item.is_active="{ item }">
      <v-chip v-if="item.is_active" color="success" dark small>
        Aktiv
      </v-chip>
      <v-chip v-else color="grey darken-3" dark small>
        Noch nicht aktiv
      </v-chip>
    </template>
    <template v-slot:item.verified_at="{ item }">
      <span v-if="item.verified_at">
        {{ item.verified_at | moment("DD.MM.YYYY") }}
      </span>
      <span v-else>
        Nicht signiert
      </span>
    </template>
    <template v-slot:item.handover_at="{ item }">
      {{ item.handover_at | moment("DD.MM.YYYY") }}
    </template>

    <template v-slot:item.start_at="{ item }">
      <span v-if="item.start_at && item.start_at_text">
        {{ item.start_at | moment("DD.MM.YYYY") }} ({{ item.start_at_text }})
      </span>
      <span v-else-if="item.start_at">
        {{ item.start_at | moment("DD.MM.YYYY") }}
      </span>
      <span v-else-if="item.start_at_text">
        {{ item.start_at_text }}
      </span>
    </template>

    <template v-slot:item.end_at="{ item }">
      <span v-if="item.end_at && item.end_at_text">
        {{ item.end_at | moment("DD.MM.YYYY") }} ({{ item.end_at_text }})
      </span>
      <span v-else-if="item.end_at_at">
        {{ item.end_at | moment("DD.MM.YYYY") }}
      </span>
      <span v-else-if="item.end_at_text">
        {{ item.end_at_text }}
      </span>
    </template>

    <template v-slot:item.handover="{ item }">
      <router-link
        v-if="item.inventoryHandover"
        class="custom-link"
        :to="{
          name: 'InventoryHandoversShow',
          params: { id: item.inventoryHandover.id }
        }"
      >
        <span>
          {{
            item.inventoryHandover ? item.inventoryHandover.name : "Gelöscht"
          }}
        </span>
      </router-link>
    </template>

    <template v-slot:item.downloadAction="{ item }">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            small
            icon
            class="mr-2"
            v-on="on"
            @click="download(item)"
          >
            <v-icon>mdi-download-outline</v-icon>
          </v-btn>
        </template>
        <span>Herunterladen</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            small
            icon
            v-on="on"
            @click="destroyUpload(item)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Löschen</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    search: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    editRoute: {
      type: String,
      default: ""
    },
    settingsRoute: {
      type: String,
      default: ""
    }
  },
  methods: {
    download(item) {
      this.axios
        .get(`/upload/${item.id}/download`, {
          responseType: "blob"
        })
        .then(r => {
          const url = URL.createObjectURL(new Blob([r.data]));
          const fileName = item.filename_original;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        });
    },
    destroyUpload(item) {
      this.axios
        .delete(`/uploads/${item.id}`)
        .then(() => {
          this.$snotify.success("Die Datei wurde erfolgreich gelöscht.");
          this.$emit("deletedUpload");
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Löschen der Datei ist ein Fehler aufgetreten."
          );
        });
    }
  }
};
</script>

<style scoped></style>
