<template>
  <div>
    <router-link
      :to="
        pageItem.attachable_type === 'App\\Models\\Document'
          ? {
              name: 'Documents',
              query: { document_id: pageItem.attachable_id }
            }
          : {
              name: 'FormsRoute',
              params: { id: pageItem.attachable_id }
            }
      "
      class="custom-link"
    >
      <v-card flat color="grey lighten-4">
        <v-list-item three-line>
          <v-list-item-action>
            <v-avatar icon color="transparent">
              <v-icon color="primary" large>
                {{
                  pageItem.is_fulfilled
                    ? "mdi-check-circle"
                    : "mdi-checkbox-blank-circle-outline"
                }}
              </v-icon>
            </v-avatar>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ pageItem.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ pageItem.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    pageItem: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({}),
  computed: {
    routerLink() {
      switch (this.pageItem.attachable_type) {
        case "App\\Models\\Document":
          return "Documents";
      }
      return "";
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
