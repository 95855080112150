<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card title="Anforderung löschen">
      <template v-slot:text>
        Möchten Sie die Anforderung wirklich löschen?
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text class="mr-2" @click="close">
          Abbrechen
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="submit">
          Löschen
          <v-icon right dark>
            mdi-delete-outline
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    pageItemId: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    // functional
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {},
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      destroyPageItem: "pageItem/destroy",
      indexPages: "page/index"
    }),

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function

      this.destroyPageItem({ id: this.pageItemId })
        .then(() => {
          this.indexPages();
          this.$snotify.success("Die Anforderung wurde erfolgreich gelöscht.");
          this.close();
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Löschen der Anforderung ist ein Fehler aufgetreten."
          );
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
