<template>
  <v-card>
    <v-card-title>
      Ihre Vertrauenspersonen
      <v-spacer />
      <models-confidant-create-dialog />
    </v-card-title>
    <organisms-base-table :items="confidants" :headers="headersConfidants" />
  </v-card>
</template>

<script>
export default {
  props: {},
  data: () => ({
    headersConfidants: [
      { text: "Name", value: "name" },
      { text: "Erstellt", value: "created_at" },
      { text: "Aktivitätsstatus", value: "is_active" },
      { value: "removeConfidant", align: "end", sortable: false }
    ]
  }),
  computed: {
    confidants() {
      return this.$store.getters["state/userDetails"].confidants;
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
