<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" text v-on="on">
        Anforderung hinzufügen
        <v-icon right dark>mdi-plus</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card
      :title="isModel ? 'Anforderung bearbeiten' : 'Anforderung hinzufügen'"
    >
      <template v-slot:text>
        <v-form ref="form" v-model="isValid" @submit.prevent="submit">
          <v-select
            v-model="pageItem.attachable_type"
            filled
            :items="types"
            label="Art"
            @input="refreshItems"
          />

          <v-fade-transition>
            <div v-if="pageItem.attachable_type === 'App\\Models\\Document'">
              <v-autocomplete
                v-model="pageItem.attachable_id"
                :items="documents"
                :loading="loadingDocuments"
                item-text="name"
                item-value="id"
                filled
                label="Dokument"
              />
            </div>
            <div v-if="pageItem.attachable_type === 'App\\Models\\Form'">
              <v-autocomplete
                v-model="pageItem.attachable_id"
                :items="forms"
                :loading="loadingForms"
                item-text="name"
                item-value="id"
                filled
                label="Verfügungseditor"
              />
            </div>
          </v-fade-transition>
          <v-fade-transition>
            <div v-if="pageItem.attachable_type">
              <!--              <v-textarea
                v-model="pageItem.body"
                label="Zusätzliche Beschreibung (Markdown zulässig)*"
                filled
                :rules="[rules.required]"
              />-->
            </div>
          </v-fade-transition>
        </v-form>
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text class="mr-2" @click="close">
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!isValid"
          :loading="loading"
          @click="submit"
        >
          Hinzufügen
          <v-icon right dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    pageItemProp: {
      type: Object,
      default: () => {}
    },
    pageId: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    // functional
    dialog: false,
    isValid: false,
    loading: false,
    loadingDocuments: false,
    loadingForms: false,

    types: [
      { text: "Dokumentupload", value: "App\\Models\\Document" },
      { text: "Verfügungseditor", value: "App\\Models\\Form" }
    ]
  }),
  computed: {
    pageItem: {
      set(val) {
        this.$store.commit("pageItem/setPageItem", val);
      },
      get() {
        return this.$store.getters["pageItem/pageItem"];
      }
    },
    documents() {
      return this.$store.getters["document/documents"];
    },
    rules() {
      return this.$store.getters["application/rules"];
    },
    isModel() {
      return !!this.pageItemProp;
    },
    forms() {
      return this.$store.getters["form/forms"];
    }
  },
  watch: {
    itemProp(val) {
      this.section = val;
    }
  },
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      indexDocuments: "document/index",
      indexForms: "form/index",
      indexPages: "page/index",
      storePageItem: "pageItem/store"
    }),

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function
      this.store();
    },
    store() {
      this.pageItem.page_id = this.pageId;
      this.storePageItem({ payload: this.pageItem })
        .then(() => {
          this.indexPages();
          this.$snotify.success(
            "Die Anforderung wurde erfolgreich hinzugefügt."
          );
          this.close();
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Hinzufügen der Anforderung ist ein Fehler aufgetreten."
          );

          this.loading = false;
        });
    },
    refreshItems() {
      if (this.pageItem.attachable_type === "App\\Models\\Document") {
        this.loadingDocuments = true;

        this.indexDocuments().finally(() => {
          this.loadingDocuments = false;
        });
      } else if (this.pageItem.attachable_type === "App\\Models\\Form") {
        this.loadingForms = true;

        this.indexForms({ onlyNoDraft: true }).finally(() => {
          this.loadingForms = false;
        });
      }
    }
  }
};
</script>

<style scoped></style>
