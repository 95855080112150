<template>
  <v-card>
    <v-card-title>
      Sie sind Vertrauensperson von
      <v-spacer />
      <models-confidant-activate-dialog />
    </v-card-title>
    <organisms-base-table
      :items="confidantPersonOf"
      :headers="headersConfidants"
      edit-route="ConfidantsShow"
    />
  </v-card>
</template>

<script>
export default {
  props: {},
  data: () => ({
    headersConfidants: [
      { text: "Name", value: "name_of" },
      { text: "Aktiviert", value: "updated_at" },
      { value: "action", sortable: false, align: "end" }
    ]
  }),
  computed: {
    confidantPersonOf() {
      return this.$store.getters["state/userDetails"].confidantPersonOf;
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
