<template>
  <div>
    <v-card>
      <v-list-item>
        <v-list-item-action>
          <v-btn small icon class="draggable" style="cursor: move">
            <v-icon>mdi-drag-horizontal-variant</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content>
          <div>
            <v-card-subtitle class="pa-0">
              {{ elementTypes[formItem.type] }}
            </v-card-subtitle>
            <template v-if="isType('section')">
              {{ formItem.name }}
            </template>
            <template v-else>
              <template v-if="formItem.name">
                <div>{{ formItem.name }}</div>
                <div class="subtitle-2">
                  {{ formItem.label }}
                </div>
              </template>
              <template v-else>
                {{ formItem.label }}
              </template>
              <div>Code: {{ "#{" + formItem.slug + "}" }}</div>
            </template>
          </div>
          <v-list-item-subtitle
            v-if="formItem.description"
            v-text="formItem.description"
          />
        </v-list-item-content>
        <v-list-item-action>
          <div class="d-flex flex-row">
            <models-form-item-parent-dialog :form-item-prop="formItem" />
            <models-form-item-delete-dialog :form-item="formItem" />
          </div>
        </v-list-item-action>
      </v-list-item>
      <div v-if="isType('section')" class="pa-4 pl-12">
        <Draggable
          v-model="formItem.children"
          handle=".draggable"
          group="children"
          style="min-height: 48px;"
          @end="updateOrder"
        >
          <template v-for="(child, index) in formItem.children">
            <div :key="`${formItem.id}-child-${index}`" class="mt-1">
              <models-form-item-item :form-item="child" />
            </div>
          </template>
        </Draggable>
        <div class="mt-2">
          <models-form-item-parent-dialog :form-item-id="formItem.id" />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    formItem: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    elementTypes: {
      section: "Abschnitt",
      "v-autocomplete": "Dropdown-Auswahl",
      "v-checkbox": "Mehrfach-Auswahl (Checkbox)",
      "v-date-picker": "Datumauswahl mit Kalender",
      "v-radio-group": "Einzel-Auswahl (Radio)",
      "v-textarea": "Text (mehrzeilig)",
      "v-text-field": "Text (einzeilig)",
      "24-input-list": "Liste mit anpassbaren Einträgen"
    }
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      updateOrderFormItem: "formItem/updateOrder"
    }),
    isType(type) {
      return this.formItem.type === type;
    },

    updateOrder() {
      this.updateOrderFormItem({
        id: this.formItem.id,
        payload: { children: this.formItem.children }
      });
    }
  }
};
</script>

<style scoped></style>
