import axios from "axios";

export default {
  index(ctx) {
    return new Promise((resolve, reject) => {
      axios
        .get("/page-items")
        .then(response => {
          resolve(response);
          ctx.commit("setPageItems", response.data.pageItems);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  store(ctx, { payload }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/page-items", { pageItem: payload })
        .then(response => {
          resolve(response);
          ctx.commit("setPageItem", response.data.pageItem);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  show(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/page-items/${id}`)
        .then(response => {
          resolve(response);
          ctx.commit("setPageItem", response.data.pageItem);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  update(ctx, { id, payload }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/page-items/${id}`, { pageItem: payload })
        .then(response => {
          resolve(response);
          ctx.commit("setPageItem", response.data.pageItem);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  destroy(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/page-items/${id}`)
        .then(response => {
          resolve(response);
          ctx.commit("setPageItem", {});
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
