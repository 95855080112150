var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.page)?_c('div',[_c('div',{staticClass:"mb-2 header"},[_c('div',{staticClass:"progress",class:_vm.page.is_fulfilled ? 'primary' : 'grey lighten-2'},[_c('div',{staticClass:"primary",staticStyle:{"height":"100%"},style:(("width: " + _vm.progress1 + "%;"))})]),_c('div',{staticClass:"badge"},[_c('router-link',{staticClass:"custom-link",attrs:{"to":{ name: 'PagesShow', params: { slug: _vm.page.slug } }}},[_c('v-avatar',{staticClass:"white--text",attrs:{"color":"primary","size":"50"}},[_vm._v(" "+_vm._s(_vm.index)+" ")])],1)],1),_c('div',{staticClass:"progress",class:_vm.page.is_fulfilled ? 'primary' : 'grey lighten-2'},[_c('div',{staticClass:"primary",staticStyle:{"height":"100%"},style:(("width: " + _vm.progress2 + "%;"))})])]),_c('div',{staticClass:"px-4 mt-4"},[_c('div',{staticClass:"text-h5 text-center"},[_vm._v(" "+_vm._s(_vm.page.name)+" ")]),(_vm.page.pageItems)?_c('v-card',{staticClass:"mt-4"},[_vm._l((_vm.page.pageItems),function(pageItem,pageIndex){return [_c('v-list-item',{key:(pageIndex + "-itemx"),attrs:{"link":"","to":pageItem.attachable_type === 'App\\Models\\Document'
                ? {
                    name: 'Documents',
                    query: { document_id: pageItem.attachable_id }
                  }
                : {
                    name: 'FormsRoute',
                    params: { id: pageItem.attachable_id }
                  }}},[_c('v-list-item-action',[_c('v-avatar',{attrs:{"icon":"","color":"transparent"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(pageItem.is_fulfilled ? "mdi-check-circle" : "mdi-checkbox-blank-circle-outline")+" ")])],1)],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(pageItem.name)+" ")])],1)]})],2):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }