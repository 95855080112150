<template>
  <v-dialog
    v-model="dialog"
    :max-width="!item.type ? '900px' : '750px'"
    persistent
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!formItemProp"
        :block="!formItemId"
        v-bind="attrs"
        v-on="on"
        @click="setFormItem"
      >
        {{ !formItemId ? "Abschnitt hinzufügen" : "Unterelement hinzufügen" }}
        <v-icon right dark>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        v-else
        icon
        small
        plain
        v-bind="attrs"
        v-on="on"
        @click="setFormItem"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card
      :title="!formItemProp ? 'Element hinzufügen' : 'Element bearbeiten'"
    >
      <template v-slot:text>
        <v-fade-transition>
          <models-form-item-parent-dialog-type v-if="!item.type" />

          <div v-else>
            <div class="mb-2 d-flex align-center">
              <span>Ausgewählter Element-Typ:</span>
              <span class="font-weight-bold ml-1">
                {{ elementTypes[item.type] }}
              </span>
              <v-spacer />
              <v-btn
                plain
                small
                color="primary"
                class="ml-2"
                @click="item = newFormItem"
              >
                Entfernen
                <v-icon color="primary" small>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form v-model="isValid" @submit.prevent="submit">
              <div v-if="isType('section')">
                <v-text-field
                  v-model="item.name"
                  counter="280"
                  filled
                  hide-details="auto"
                  label="Titel *"
                  :rules="[rules.required, rules.short_text]"
                />
                <v-textarea
                  v-model="item.description"
                  filled
                  hide-details="auto"
                  label="Inhalt (Markdown)"
                  class="mb-2"
                />

                <v-select
                  v-model="item.label"
                  filled
                  hide-details="auto"
                  :items="sectionTypes"
                  label="Art *"
                  :rules="[rules.required]"
                />
              </div>
              <div v-else>
                <v-text-field
                  v-model="item.name"
                  counter="280"
                  filled
                  hide-details="auto"
                  label="Überschrift (optional)"
                  :rules="[rules.short_text]"
                />
                <v-text-field
                  v-model="item.label"
                  counter="280"
                  filled
                  hide-details="auto"
                  label="Bezeichnung *"
                  :rules="[rules.required, rules.short_text]"
                />
                <v-text-field
                  v-if="
                    isType('v-autocomplete') ||
                      isType('v-textarea') ||
                      isType('v-text-field')
                  "
                  v-model="item.placeholder"
                  counter="280"
                  filled
                  hide-details="auto"
                  label="Platzhalter (z.B. bei Vorname 'Max')"
                  :rules="[rules.short_text]"
                />

                <!-- items -->
                <div
                  v-if="
                    isType('v-autocomplete') ||
                      isType('v-checkbox') ||
                      isType('v-radio-group') ||
                      isType('24-input-list')
                  "
                >
                  <div class="text-overline">
                    Auswahlmöglichkeiten
                  </div>
                  <v-card class="mb-2">
                    <Draggable v-model="item.items" handle=".draggable">
                      <v-list-item
                        v-for="(option, index) in item.items"
                        :key="`form-item-option-${index}`"
                      >
                        <v-list-item-action>
                          <v-btn icon small class="draggable">
                            <v-icon small>
                              mdi-drag
                            </v-icon>
                          </v-btn>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ option.label }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ option.result }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            icon
                            small
                            @click="item.items.splice(index, 1)"
                          >
                            <v-icon small>
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </Draggable>
                  </v-card>
                  <v-text-field
                    v-model="newOptionList.label"
                    clearable
                    filled
                    label="Auswahlbezeichnung"
                    hide-details
                  />
                  <v-textarea
                    v-model="newOptionList.result"
                    clearable
                    filled
                    label="Textausgabe (Markdown)"
                    hide-details
                  />
                  <div class="d-flex">
                    <v-spacer />

                    <v-btn class="mt-2" @click="addOption">
                      Hinzufügen
                    </v-btn>
                  </div>
                </div>

                <v-checkbox
                  v-if="isType('v-autocomplete')"
                  v-model="item.is_multiple"
                  label="Mehrfachantworten zulassen"
                />
              </div>
            </v-form>
          </div>
        </v-fade-transition>
      </template>
      <template v-slot:actions>
        <v-spacer />
        <v-btn text class="mr-2" @click="close">
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!isValid"
          :loading="loading"
          @click="submit"
        >
          {{ !formItemProp ? "Hinzufügen" : "Speichern" }}
          <v-icon v-if="!formItemProp" right dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    formItemId: {
      type: String,
      default: ""
    },
    formItemProp: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    // functional
    newOption: "",
    newOptionList: {},
    sectionTypes: [
      { text: "Hauptabschnitt", value: "main" },
      { text: "Unterabschnitt", value: "sub" }
    ],
    elementTypes: {
      section: "Abschnitt",
      "v-autocomplete": "Dropdown-Auswahl",
      "v-checkbox": "Mehrfach-Auswahl (Checkbox)",
      "v-date-picker": "Datumauswahl mit Kalender",
      "v-radio-group": "Einzel-Auswahl (Radio)",
      "v-textarea": "Text (mehrzeilig)",
      "v-text-field": "Text (einzeilig)",
      "24-input-list": "Liste mit anpassbaren Einträgen"
    },
    newFormItem: {
      type: "",
      name: "",
      description: "",
      label: "",
      placeholder: "",
      items: [],
      rules: [],
      is_multiple: false,
      is_symbol: false
    },
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    item: {
      set(val) {
        this.$store.commit("formItem/setFormItem", val);
      },
      get() {
        return this.$store.getters["formItem/formItem"];
      }
    },
    form: {
      set(val) {
        this.$store.commit("form/setForm", val);
      },
      get() {
        return this.$store.getters["form/form"];
      }
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    dialog() {
      this.newOptionList = {};
      this.newOption = "";
    }
  },
  created() {
    if (!this.formItemProp) {
      this.$store.commit("formItem/setFormItem", { ...this.newFormItem });
    }
  },
  methods: {
    ...mapActions({
      storeFormItem: "formItem/store",
      updateFormItem: "formItem/update"
    }),

    // functional
    addOption() {
      if (this.newOptionList) {
        this.item.items.push(this.newOptionList);
        this.newOptionList = {};
      }
    },

    isType(type) {
      return this.item.type === type;
    },

    setFormItem() {
      if (this.formItemProp) {
        this.item = this.formItemProp;
      } else {
        this.item = { ...this.newFormItem };
        if (!this.formItemId) {
          this.item.type = "section";
        }
      }
    },

    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function

      if (!this.formItemProp) {
        this.item.form_id = this.form.id;
        this.item.form_item_id = this.formItemId;
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.storeFormItem({
        payload: {
          formItem: this.item
        }
      })
        .then(r => {
          this.form = { ...r.data.form };
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    update() {
      this.updateFormItem({
        id: this.item.id,
        payload: {
          formItem: this.item
        }
      })
        .then(r => {
          this.form = { ...r.data.form };
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
