export default {
  forms(state) {
    return state.forms;
  },
  formsOwn(state) {
    return state.formsOwn;
  },
  form(state) {
    return state.form;
  },
  saving(state) {
    return state.saving;
  }
};
