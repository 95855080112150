<template>
  <div>
    <v-btn
      v-if="isSettings"
      class="mb-4"
      :to="{ name: 'FormsEdit', params: { id: form.id } }"
      target="_blank"
    >
      Vorschau für Anwender
      <v-icon right>mdi-open-in-new</v-icon>
    </v-btn>
    <div :key="updateKey">
      <VueMarkdown v-if="form.id">
        {{ filteredText() }}
      </VueMarkdown>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    isSettings: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    // functional
    updateKey: 0,
    isValid: false,
    loading: false
  }),
  computed: {
    form: {
      set(val) {
        this.$store.commit("form/setForm", val);
      },
      get() {
        return this.$store.getters["form/form"];
      }
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.updateKey++;
      }
    }
  },
  created() {},
  methods: {
    ...mapActions({}),
    filteredText() {
      if (!this.form.body) return "";
      let string = this.form.body.replaceAll(/#{(.+?)}/g, this.replaceBody);
      return string;
    },
    replaceBody(string, first) {
      const inputs = this.form.formInputItems;

      if (!inputs[first]) return "<b>!!!GIBT ES NICHT!!!</b>";

      const item = inputs[first];

      function isType(type) {
        return item.type === type;
      }
      if (
        (isType("v-autocomplete") && !item.is_multiple) ||
        isType("v-textarea") ||
        isType("v-text-field") ||
        isType("v-radio-group") ||
        isType("v-date-picker")
      ) {
        if (item.value) return `<span id="${item.id}">${item.value}</span>`;
      } else {
        if (item.value && item.value.length > 0) {
          let string = "<ul>";

          item.value.forEach(val => {
            string += `<li>${val}</li>`;
          });

          string += "</ul>";
          return string;
        } else {
          if (!this.isSettings) {
            return `<ul><li>________________</li></ul>`;
          } else {
            return `<span class='primary--text font-weight-bold'>${item.label}</span><ul class='primary--text font-weight-bold'><li>________________</li></ul>`;
          }
        }
      }

      if (this.isSettings) {
        if (item.name)
          return (
            "<span class='primary--text font-weight-bold'>" +
            item.name +
            "</span>"
          );
        if (item.label)
          return (
            "<span class='primary--text font-weight-bold'>" +
            item.label +
            "</span>"
          );
        return "!!!Kein Titel!!!";
      }
      return `<span id="${item.id}">________________</span>`;
    }
  }
};
</script>

<style scoped></style>
