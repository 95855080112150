import Vue from "vue";
import VueRouter from "vue-router";
import NoAuthWrapper from "../views/noauth/Wrapper";
import PassThrough from "@/views/auth/views/PassThrough";
import PassThroughMain from "@/views/auth/views/PassThroughMain";
import Login from "@/views/noauth/Login";
import ForgotPassword from "@/views/noauth/ForgotPassword";

import Dashboard from "@/views/auth/views/Dashboard";

import Middleware from "../middleware/index";
import Home from "@/views/noauth/Home";
import ResetPassword from "@/views/noauth/ResetPassword";

/*
 * Routes
 */

import pages from "./pages";
import settingsRoutes from "./settings";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: NoAuthWrapper,
    children: [
      {
        path: "",
        name: "Home",
        components: {
          main: Home
        },
        meta: {
          nav: false,
          middleware: [Middleware.guest]
        }
      },
      {
        path: "/login",
        name: "Login",
        components: {
          main: Login
        },
        meta: {
          nav: false,
          middleware: [Middleware.guest]
        }
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        components: {
          main: ForgotPassword,
          middleware: [Middleware.guest]
        },
        meta: {
          nav: false
        }
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        components: {
          main: ResetPassword,
          middleware: [Middleware.guest]
        },
        meta: {
          nav: false
        }
      }
    ]
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "",
    name: "AuthWrapper",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/auth/Wrapper"),
    children: [
      ...pages,

      {
        path: "/dashboard",
        name: "Dashboard",
        components: {
          main: Dashboard
        },
        meta: {
          barTitle: "Dashboard",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/profile/:id",
        name: "UserProfile",
        components: {
          main: () =>
            import(/* webpackChunkName: "auth" */ "../views/auth/views/Profile")
        },
        meta: {
          barTitle: "Profil",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/documents",
        name: "Documents",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/Upload/Document"
            )
        },
        meta: {
          barTitle: "Dokumente",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/confidants/:id",
        name: "ConfidantsShow",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/Confidants/Documents"
            )
        },
        meta: {
          barTitle: "Unterlagen einer Person betrachten",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/forms/:id/edit",
        name: "FormsEdit",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/Forms/Edit"
            )
        },
        meta: {
          barTitle: "Formular bearbeiten",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/forms/:id/route",
        name: "FormsRoute",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/Forms/Route"
            )
        },
        meta: {
          barTitle: "Formular bearbeiten",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/settings",
        name: "Settings",
        components: {
          main: PassThrough
        },
        children: [
          ...settingsRoutes,
          {
            path: "instance-settings",
            component: PassThroughMain,
            children: [
              {
                path: "",
                name: "SettingsInstanceSettings",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/InstanceSettings/Index"
                    )
                },
                meta: {
                  barTitle: "Systemeinstellungen",
                  middleware: [Middleware.auth],
                  permissions: ["system-admin"]
                }
              }
            ]
          },
          {
            path: "teams",
            component: PassThroughMain,
            children: [
              {
                path: "",
                name: "SettingsTeams",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Teams/Index"
                    )
                },
                meta: {
                  barTitle: "Teams",
                  middleware: [Middleware.auth],
                  permissions: ["system-admin"]
                }
              },
              {
                path: "form",
                name: "SettingsTeamsCreate",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Teams/Form"
                    )
                },
                meta: {
                  barTitle: "Team erstellen",
                  middleware: [Middleware.auth],
                  permissions: ["system-admin"]
                }
              },
              {
                path: "form/:id",
                name: "SettingsTeamsEdit",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Teams/Form"
                    )
                },
                meta: {
                  barTitle: "Team bearbeiten",
                  middleware: [Middleware.auth],
                  permissions: ["system-admin"]
                }
              }
            ]
          },
          {
            path: "users",
            component: PassThroughMain,
            children: [
              {
                path: "",
                name: "SettingsUsers",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Users/Index"
                    )
                },
                meta: {
                  barTitle: "Nutzer",
                  middleware: [Middleware.auth],
                  permissions: ["system-admin"]
                }
              },
              {
                path: "form",
                name: "SettingsUsersCreate",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Users/Form"
                    )
                },
                meta: {
                  barTitle: "Nutzer erstellen",
                  middleware: [Middleware.auth],
                  permissions: ["system-admin"]
                }
              },
              {
                path: "form/:id",
                name: "SettingsUsersEdit",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Users/Form"
                    )
                },
                meta: {
                  barTitle: "Nutzer bearbeiten",
                  middleware: [Middleware.auth],
                  permissions: ["system-admin"]
                }
              }
            ]
          }
        ]
      },
      {
        path: "/teams",
        name: "Teams",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/teams/Index"
            )
        },
        meta: {
          barTitle: "Ihre Teams",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/teams/:id",
        name: "TeamsShow",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/teams/Show"
            )
        },
        meta: {
          barTitle: "Ansicht Team",
          middleware: [Middleware.auth],
          permissions: ["consultant permission"]
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
});

function nextCheck(context, middleware, index) {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMidd = nextCheck(context, middleware, index + 1);

    nextMiddleware({ ...context, next: nextMidd });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const ctx = {
      from,
      next,
      router,
      to
    };

    const nextMiddleware = nextCheck(ctx, middleware, 1);

    return middleware[0]({ ...ctx, next: nextMiddleware });
  }

  return next();
});

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? `${to.meta.title} • NALA`
      : to.meta.barTitle
      ? `${to.meta.barTitle} • NALA`
      : "NALA";
  });
});

export default router;
