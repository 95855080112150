<template>
  <div :id="`${formItem.id}-section`">
    <div :class="`pb-6`">
      <div class="mb-5">
        <div
          v-if="formItem.name"
          class="font-weight-bold"
          :class="
            `
            ${formItem.description ? 'mb-3' : ''}
            ${formItem.label === 'main' ? 'text-h4' : 'text-h5'}
            `
          "
          v-text="formItem.name"
        />
        <div v-if="formItem.description">
          <VueMarkdown>{{ formItem.description }}</VueMarkdown>
        </div>
      </div>

      <template v-for="(input, index) in formItem.children">
        <models-form-item-parent-element
          :key="`${formItem.id}-input-${index}`"
          :form-item="input"
          :class="`${index !== 0 ? 'mt-2' : ''}`"
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formItem: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
