<template>
  <div style="width: 100%;">
    <v-row dense class="fill-height ">
      <v-col cols="12" xl="6" lg="6" md="6" class="pa-0 hidden-sm-and-down">
        <v-img src="@/assets/login-bg.svg" height="100%" width="100%">
          <v-overlay color="primary" opacity="0.7" style="width: 50%">
            <v-card
              dark
              color="transparent"
              flat
              class="pa-16 d-flex flex-column justify-center align-center"
            >
              <v-card-title class="text-h2 text-center">
                Behalten Sie den Überblick mit
              </v-card-title>
              <img
                class="mt-5"
                alt="NALA"
                src="@/assets/logo.svg"
                style="filter: invert(1); width: 30%"
              />
            </v-card>
          </v-overlay>
        </v-img>
      </v-col>
      <v-col
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
        class="pa-lg-16 pa-md-0 white d-flex justify-center align-center"
      >
        <v-card
          color="white"
          :width="$vuetify.breakpoint.mdAndDown ? '90%' : '70%'"
          flat
          class="ma-md-16 ma-sm-0"
        >
          <v-card-title v-if="settings.brand_image" class="py-0">
            <img
              :src="brandUrl"
              style="max-height: 50px; max-width: 100%;"
              :alt="settings.name"
              class="mr-10"
            />
          </v-card-title>
          <v-card-title class="text-h4">
            Login
          </v-card-title>
          <v-card-text>
            <v-alert v-model="alert" dense text type="error" class="mb-6">
              Die E-Mail-Adresse und/oder das Passwort ist falsch.
            </v-alert>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="submitLoginForm"
            >
              <v-text-field
                v-model="user.email"
                class="mt-1"
                filled
                label="E-Mail-Adresse"
                :rules="emailRules"
                clearable
              >
              </v-text-field>
              <v-text-field
                v-model="user.password"
                filled
                label="Passwort"
                :rules="[v => !!v || 'Passwort wird benötigt.']"
                type="password"
                clearable
              ></v-text-field>
              <v-btn
                class="mt-3"
                type="submit"
                color="primary"
                block
                :disabled="!valid"
                :loading="loading"
                >Anmelden</v-btn
              >
              <v-btn
                class="mt-3"
                color="primary"
                block
                text
                :to="{ name: 'ForgotPassword' }"
                >Passwort vergessen</v-btn
              >
            </v-form>
            <div v-if="environment === 'development'" class="mt-5">
              <v-btn
                block
                depressed
                class="mt-1"
                @click="
                  user.email = 'admin@demo.institut-ida.de';
                  user.password = 'admin@demo.institut-ida.de';
                "
              >
                Systemadmin
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  data: () => ({
    alert: false,
    loading: false,
    valid: true,
    dialog: false,
    user: {
      email: "",
      password: ""
    },
    emailRules: [
      v => !!v || "E-Mail-Adresse wird benötigt.",
      v => /.+@.+\..+/.test(v) || "E-Mail-Adresse ist ungültig."
    ]
  }),
  computed: {
    brandUrl() {
      return this.$store.getters["state/brandUrl"];
    },
    settings() {
      return this.$store.getters["state/settings"];
    },

    environment() {
      return process.env.VUE_APP_ENV_TYPE;
    }
  },
  created() {
    if (this.settings.primary_color) {
      this.$vuetify.theme.themes.light.primary = this.settings.primary_color;
      if (this.settings.secondary_color) {
        this.$vuetify.theme.themes.light.secondary = this.settings.secondary_color;
      }
    }
  },
  methods: {
    ...mapActions({
      vuexLogin: "state/login"
    }),
    submitLoginForm() {
      if (this.$refs.form.validate()) {
        this.alert = false;
        this.loading = true;
        this.vuexLogin(this.user)
          .then(() => {
            // this.$snotify.success("Erfolgreich angemeldet.");
            if (this.$route.query.refererNoAuth) {
              this.$router.push({ path: this.$route.query.refererNoAuth });
            } else {
              this.$router.push({ name: "Dashboard" });
            }
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
            if (
              error.data &&
              error.data.message &&
              error.data.message === "Unauthenticated"
            ) {
              this.alert = true;
            } else {
              this.$snotify.error("Ein Fehler ist aufgetreten.");
            }
          });
      }
    }
  }
};
</script>

<style scoped></style>
