<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" xl="11" lg="12">
        <v-row>
          <v-col cols="12" md="12">
            <v-card flat color="transparent">
              <v-row no-gutters>
                <v-col cols="12" md="12" class="pr-10 py-6">
                  <div class="text-h3">
                    Willkommen bei NALA!
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <v-card flat color="accent">
              <v-row no-gutters>
                <v-col cols="12" md="12" class="pr-10 py-6">
                  <v-card-title class="text-h4">
                    <dashboard-greeting />
                  </v-card-title>
                  <v-card-text class="text-h5">
                    Genießen Sie den smarten Service, den Sie verdient haben.
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col v-for="(card, index) in cards" :key="index" cols="12" md="4">
            <v-hover v-slot="{ hover }" class="transition-ease-in-out">
              <v-card
                :elevation="hover ? 8 : 2"
                :to="card.to"
                class="d-flex justify-space-between flex-column"
                height="100%"
              >
                <div>
                  <v-card-title class="align-center">
                    <v-icon class="mr-3">mdi-archive-outline</v-icon>
                    <div>{{ card.title }}</div>
                  </v-card-title>
                  <v-card-text>{{ card.description }}</v-card-text>
                </div>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>

        <div class="text-overline mt-4">
          Vorschau
        </div>
        <div class="mb-4">
          <organisms-pages-timeline />
        </div>

        <div class="text-overline mt-16">
          Vorsorgelevel
        </div>
        <div v-if="pages && pages[0]" class="mb-4">
          <v-card class="d-flex flex-nowrap align-center justify-start">
            <div class="pa-4">
              <v-progress-circular
                rotate="90"
                color="primary"
                size="240"
                width="20"
                :value="progressValue"
              >
                <span class="text-h4">{{ progressValueText }}</span>
              </v-progress-circular>
            </div>
            <div class="pa-4 flex-grow-1">
              <div class="text-h5 mb-4">
                Empfehlung für die nächsten Schritte
              </div>
              <v-card v-if="pages[0].pageItems" class="mt-4">
                <template v-for="(pageItem, pageIndex) in pages[0].pageItems">
                  <v-list-item
                    :key="`${pageIndex}-itemx`"
                    link
                    :to="
                      pageItem.attachable_type === 'App\\Models\\Document'
                        ? {
                            name: 'Documents',
                            query: { document_id: pageItem.attachable_id }
                          }
                        : {
                            name: 'FormsRoute',
                            params: { id: pageItem.attachable_id }
                          }
                    "
                  >
                    <v-list-item-action>
                      <v-avatar icon color="transparent">
                        <v-icon color="primary">
                          {{
                            pageItem.is_fulfilled
                              ? "mdi-check-circle"
                              : "mdi-checkbox-blank-circle-outline"
                          }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-action>

                    <v-list-item-content>
                      {{ pageItem.name }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-card>
            </div>
          </v-card>
        </div>

        <div class=" pt-16">
          <models-confidant-overview />
        </div>

        <div class=" pt-16">
          <models-confidant-overview-of />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    cards: []
  }),
  computed: {
    progressValue() {
      if (this.pages.length > 0) {
        let done = 0;
        let sum = 0;
        this.pages.forEach(page => {
          done += page.pageItems.filter(item => !!item.is_fulfilled).length;
          sum += page.pageItems.length;
        });
        return Math.round((done / sum) * 100);
      }
      return 0;
    },
    progressValueText() {
      if (this.pages.length > 0) {
        let done = 0;
        let sum = 0;
        this.pages.forEach(page => {
          done += page.pageItems.filter(item => !!item.is_fulfilled).length;
          sum += page.pageItems.length;
        });
        return `${done}/${sum}`;
      }
      return `0/0`;
    },
    pages() {
      return this.$store.getters["page/pages"];
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
