<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">
        Zugang aktivieren
        <v-icon right dark>mdi-plus</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card title="Zugang aktivieren">
      <template v-slot:text>
        <v-form v-model="isValid" @submit.prevent="submit">
          <p>
            Vertrauenspersonen können Zugriff auf die Unterlagen einer anderen
            Person erhalten.
          </p>
          <div class="mt-4">
            <v-text-field
              v-model="activation_code"
              label="Aktivierungscode"
              filled
              :rules="[rules.required]"
            />
          </div>
        </v-form>
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text class="mr-2" @click="close">
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!isValid"
          :loading="loading"
          @click="submit"
        >
          Hinzufügen
          <v-icon right dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    itemProp: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    activation_code: "",

    // functional
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    itemProp(val) {
      this.section = val;
    }
  },
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      activateConfidant: "confidant/activate",
      getState: "state/userState"
    }),

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function
      this.activateConfidant({
        payload: { confidant: { activation_code: this.activation_code } }
      })
        .then(() => {
          this.$snotify.success("Erfolgreich hinzugefügt.");
          this.getState();
        })
        .finally(() => {
          this.close();
        });
    }
  }
};
</script>

<style scoped></style>
