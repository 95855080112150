<template>
  <v-form ref="form" v-model="isValid">
    <div id="form-form-header">
      <div class="text-overline mb-2">
        Allgemein
      </div>
      <v-text-field
        v-model="form.name"
        filled
        label="Titel *"
        hide-details="auto"
        :rules="[rules.required, rules.short_text]"
        counter="280"
        @input="updateValue"
      />
      <v-textarea
        v-model="form.description"
        filled
        label="Beschreibung *"
        hide-details="auto"
        :rules="[rules.required, rules.long_text]"
        counter="6000"
        @input="updateValue"
      />
    </div>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  props: {},
  data: () => ({
    // functional
    draftLabel: {
      name: "Entwurf",
      description: "Ein Entwurf ist durch Mandanten nicht auswählbar.",
      icon: "mdi-file-outline",
      color: "grey lighten-2"
    },
    noDraftLabel: {
      name: "Veröffentlicht",
      description:
        "Ein veröffentlichtes Formular ist durch Mandanten auswählbar.",
      icon: "mdi-check-circle",
      color: "success"
    },
    isValid: false,
    loading: false
  }),
  computed: {
    form: {
      set(val) {
        this.$store.commit("form/setForm", val);
      },
      get() {
        return this.$store.getters["form/form"];
      }
    },
    rules() {
      return this.$store.getters["application/rules"];
    },
    saving: {
      set(val) {
        this.$store.commit("form/setSaving", val);
      },
      get() {
        return this.$store.getters["form/saving"];
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      updateForm: "form/update",
      updateOrderForm: "form/updateOrder"
    }),
    updateOrder() {
      this.updateOrderForm({
        id: this.form.id,
        payload: { formSectionItems: this.form.formSectionItems }
      });
    },
    updateValue: _.debounce(function() {
      this.saving = true;

      this.updateForm({
        id: this.form.id,
        payload: { form: this.form }
      }).finally(() => {
        this.saving = false;
      });
    }, 500)
  }
};
</script>

<style scoped></style>
