<template>
  <div>
    <div v-if="formItem.label" class="mb-2" v-text="formItem.label" />
    <v-radio-group v-model="formItem.value" hide-details="auto">
      <template v-for="(radio, index) in formItem.items">
        <v-radio
          :key="`${formItem.id}-radio-${index}`"
          :label="radio.label"
          :value="radio.result"
        />
      </template>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    formItem: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({}),
  computed: {},
  watch: {
    formItem: {
      deep: true,
      handler(val) {
        this.$emit("update", val.value);
      }
    }
  },
  created() {},
  methods: {}
};
</script>

<style scoped></style>
